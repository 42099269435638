import React, { useMemo, useState } from 'react';
import { Button, Modal, Table, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updatePaymentIntentThunk } from '../../../store/stripe';
import Swal from 'sweetalert2'
import { deleteOrderThunk } from '../../../store/orders';

const OrderDetailsModal = ({ showModal, selectedOrder, setShowModal, fees }) => {
  const dispatch = useDispatch();
  const [newChargeModal, setNewChargeModal] = useState(false);
    const id = useSelector((state) => state.session.user.id);

  const formatDates = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleDateString();
  };

  const formatTime = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleTimeString();
  };

  const checkDateOver72Hours = (date) => { 
    const currentDate = new Date();
    const orderDate = new Date(date);
    const diffTime = Math.abs(currentDate - orderDate); // Difference in milliseconds
    const diffHours = diffTime / (1000 * 60 * 60); // Convert milliseconds to hours
    
    return diffHours > 72;
  }
  

  const handleOpenNewChargeModal = () => {
    if(checkDateOver72Hours(selectedOrder?.createdAt)){
      Swal.fire({
        title: 'Error!',
        text: 'You cannot update over charger after 72 hours of the order. Please contact support for further assistance.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }else { 
      setNewChargeModal(true);
    }
  };



  const updatePaymentIntent = (id, tipAmount, order_id, restaurant_id) => {

    dispatch(updatePaymentIntentThunk(id, tipAmount, order_id, restaurant_id))
      .then((response) => {
        if (response.ok) {
          setNewChargeModal(false);
          setShowModal(false);
          Swal.fire({
            title: 'Success!',
            text: 'The tip amount has been updated successfully. You can Check Your Payment to see the charge.',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'There was an issue updating the tip amount.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((error) => {
        console.error('Error updating tip:', error);
        Swal.fire({
          title: 'Error!',
          text: 'There was an issue updating the tip amount.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
  };

  const updateOrder = (order_id, restaurant_id) => {
    dispatch(deleteOrderThunk(order_id, restaurant_id, id))
      setShowModal(false);
          Swal.fire({
            title: 'Success!',
            text: 'The order has been deleted successfully.',
            icon: 'success',
            confirmButtonText: 'OK',
          });
    } 
      
   


  

  const MemoizedOrderDetailsModal = useMemo(() => {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Order #{selectedOrder?.order_id}</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <Row>
            <Col lg={7} style={{ maxHeight: '60vh', overflowY: 'auto' }}>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Quantity</th>
                    <th>Item</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder?.OrderItems?.map((item) => (
                    <tr key={item.id}>
                      <td>{item?.quantity}</td>
                      <td>
                        <div>
                          <strong>{item?.Item?.chinese_name} <br />{item?.Item?.name}</strong>
                        </div>
                        {item?.Order_Item_Attributes?.map((attribute) => (
                          <div key={attribute.id} className="text-muted">
                            -{attribute?.Item_Attribute_Option?.name}
                            <br />
                            {' '}-{attribute?.Item_Attribute_Option?.chinese_name}
                          </div>
                        ))}
                        {item?.special_instructions && (
                          <div className="text-muted">
                            **{item?.special_instructions}
                          </div>
                        )}
                      </td>
                      <td>${item?.item_price}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col lg={5}>
              <div>
                <Row>
                  <Col xs={6}><strong>Order Date:</strong></Col>
                  <Col xs={6}>{formatDates(selectedOrder?.createdAt)} / {formatTime(selectedOrder?.createdAt)}</Col>
                </Row>
                <Row>
                  <Col xs={6}><strong>Order Status:</strong></Col>
                  <Col xs={6}>
                    <span className={selectedOrder?.order_status === 'CLOSE' ? 'text-success' : 'text-warning'}>
                      {selectedOrder?.order_status}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}><strong>Payment:</strong></Col>
                  <Col xs={6}><span className={selectedOrder?.payment_method === 'in-store' ? 'text-warning' : 'text-success'}>{selectedOrder?.payment_method === 'in-store' ? 'UNPAID' : 'PAID'}</span></Col>
                </Row>
                <Row>
                  <Col xs={6}><strong>Pickup Time:</strong></Col>
                  <Col xs={6}>{selectedOrder?.pickup_time}</Col>
                </Row>
                {selectedOrder?.address && selectedOrder?.address !== 'Pickup' && (
                  <Row>
                  <div className="border-top my-3"></div>
                  <Col xs={6}><strong>Address:</strong></Col>
                  <Col xs={6}>{selectedOrder?.address}</Col>
                </Row>
                )}
                <div className="border-top my-3"></div>
                <Row>
                  <Col xs={6}><strong>Name:</strong></Col>
                  <Col xs={6}>{selectedOrder?.name}</Col>
                </Row>
                <Row>
                  <Col xs={6}><strong>Phone Number:</strong></Col>
                  <Col xs={6}>{selectedOrder?.phone_number}</Col>
                </Row>
                <div className="border-top my-3"></div>
                <Row>
                  <Col xs={6}><strong>Subtotal:</strong></Col>
                  <Col xs={6}>${selectedOrder?.subtotal}</Col>
                </Row>
                <Row>
                  <Col xs={6}><strong>Tax:</strong></Col>
                  <Col xs={6}>${selectedOrder?.tax}</Col>
                </Row>
                <Row>
                  <Col xs={6}><strong>Processing Fee:</strong></Col>
                  <Col xs={6}>${fees?.processing_fee * selectedOrder?.subtotal}</Col>
                </Row>
                {selectedOrder?.address && selectedOrder?.address === 'Pickup' && (

                <Row>
                  <Col xs={6}><strong>Con Fee:</strong></Col>
                  <Col xs={6}>${selectedOrder?.convenience_fee}</Col>
                </Row>
                )}
          
                <Row>
                  <Col xs={6}><strong>Tip:</strong></Col>
                  <Col xs={6}>${selectedOrder?.tip}</Col>
                </Row>
                {selectedOrder?.address && selectedOrder?.address !== 'Pickup' && (
                  <>
                <Row>
                  <Col xs={6}><strong>Service Fee:</strong></Col>
                  <Col xs={6}>${fees?.service_fee * selectedOrder?.subtotal + selectedOrder?.convenience_fee }</Col>
                </Row>
                  <Row>
                  <Col xs={6}><strong>Delivery Fee:</strong></Col>
                  <Col xs={6}>${fees?.delivery_fee}</Col>
                </Row>
                </>
                )}
{selectedOrder?.coupon_code?.length > 9 && (
    <Row>
        <Col xs={6}><strong>Discount:</strong></Col>
        <Col xs={6}>
            {/* Split the coupon code into code and price */}
            {selectedOrder?.coupon_code.split('-')?.map((part, index) => {
                if (index === 0) {
                    // First part is the code
                    return <span key={index}><strong>Code:</strong> {part} <br/></span>;
                } else if (index === 1) {
                    // Second part is the price
                    return <span key={index}><strong>Discount:</strong>-${part}</span>;
                }
                return null;
            })}
        </Col>
    </Row>
)}
                <div className="border-top my-3"></div>
                <Row>
                  <Col xs={6}><h5>Total Price:</h5></Col>
                  <Col xs={6}><h5><span className="text-primary">${selectedOrder?.order_total}</span></h5></Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
        <button type="button" className="btn btn-danger" onClick={() => updateOrder(selectedOrder?.order_id, selectedOrder?.restaurant_id)}>Delete Order</button>


               <Button
           variant="primary" 
           onClick={() => handleOpenNewChargeModal()}
           >
            Update Tip
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>

        {/* New Charge Modal */}
        <Modal
          show={newChargeModal}
          onHide={() => setNewChargeModal(false)}
          backdrop="static" // Use static backdrop to prevent closing by clicking outside
          keyboard={false} // Prevent closing with the keyboard (ESC key)
          style={{ zIndex: 1055 }} // Ensure this modal is on top
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>New Charge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <label htmlFor="tipAmount">Enter Amount:</label>
                <input
          type="number"
          id="tipAmount"
          className="form-control"
          placeholder="Enter Tip amount"
          step="0.01" // Allows input with up to 2 decimal places
          min="0" // Prevents negative numbers
          pattern="^\d+(\.\d{0,2})?$" // Regex pattern to allow whole numbers and up to 2 decimal places
          onInput={(e) => {
            // Ensure value is limited to 2 decimal places
            const value = e.target.value;
            if (value.includes('.')) {
              const parts = value.split('.');
              if (parts[1].length > 2) {
                e.target.value = `${parts[0]}.${parts[1].slice(0, 2)}`;
              }
            }
          }}
        />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                const tipAmount = document.getElementById('tipAmount').value;
                updatePaymentIntent(selectedOrder?.payment_id, tipAmount, selectedOrder?.id, selectedOrder?.restaurant_id);
                setNewChargeModal(false);
              }}
            >
              Charge
            </Button>
            <Button variant="secondary" onClick={() => setNewChargeModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal>
    );
  }, [showModal, selectedOrder, setShowModal, newChargeModal]);

  return MemoizedOrderDetailsModal;
};

export default OrderDetailsModal;
