import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Pagination, Table } from "react-bootstrap";
import { getCustomerThunk, updatePointsThunk } from "../../../store/customers";
import swall from 'sweetalert2';
import { orderHistoryThunk } from "../../../store/rewards";
import { getFeesThunk } from "../../../store/fees";
import OrderDetailsModal from "../Orders/OrderDetailModal";

function AllCustomers() {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.customers);
  const orderHistory = useSelector((state) => state.rewards.orderHistory);
  const [currentPage, setCurrentPage] = useState(1);
  const [points, setPoints] = useState(0);
  const [modal, setModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderHistoryPage, setOrderHistoryPage] = useState(1);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const fees = useSelector((state) => state.fees.fees);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getCustomerThunk(currentPage));
  }, [dispatch, currentPage]);
   useEffect(() => { 
      dispatch(getFeesThunk())
    },[dispatch])

  useEffect(() => {
      dispatch(orderHistoryThunk(selectedCustomerId, orderHistoryPage))
  }, [dispatch, selectedCustomerId, orderHistoryPage]);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
  };

  const handleHistoryPageChange = (pageNumber) => {
    if (pageNumber > 0) {
      setOrderHistoryPage(pageNumber);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }

  const handleUpdatePoints = (id) => {
    dispatch(updatePointsThunk(id, points));
    setPoints(0);

    swall.fire({
      title: 'Points Updated',
      text: 'Points have been updated successfully',
      icon: 'success',
      confirmButtonText: 'Ok'
    });
    setModal(false);
  };



  const openUpdatePointsModal = (id) => {
    setSelectedCustomerId(id);
    setModal(true);
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">All Customers</h5>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Id#</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Points</th>
                <th>Since</th>
                <th>Update Points</th>
                <th>Order History</th>
              </tr>
            </thead>
            <tbody>
              {customers?.map((customer) => (
                <tr key={customer.id}>
                  <td>{customer?.id}</td>
                  <td>{customer?.name}</td>
                  <td>{customer?.phone}</td>
                  <td>{customer?.points}</td>
                  <td>{formatTimestamp(customer?.createdAt)}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => openUpdatePointsModal(customer?.id)}
                    >
                      Update Points
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => 
                      {
                        setHistoryModal(true)
                        setSelectedCustomerId(customer?.id)
                      }}

                    >
                      Order History
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center">
            <Pagination>
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
              <Pagination.Item>{currentPage}</Pagination.Item>
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
            </Pagination>
          </div>
        </div>
      </div>

      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Points</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="points">Points</label>
            <input
              type="number"
              className="form-control"
              id="points"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleUpdatePoints(selectedCustomerId)}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={historyModal} onHide={() => setHistoryModal(false)} size="lg">
        <Modal.Header closeButton>
        <Modal.Title>Order History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Order Total</th>
                  <th>Payment Method</th>
     
            
                  <th>Pickup Time</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {orderHistory?.map((order) => (
                  <tr key={order.order_id} style={{ cursor: "pointer" }} onClick={() => handleOrderClick(order)}>
                    <td>{order.order_id}</td>
                    <td>{order.name}</td>
                    <td>{order.phone_number}</td>
                    <td>{order.order_total}</td>
                    <td>{order.payment_method}</td>
                    <td>{order.pickup_time}</td>
                    <td>{formatTimestamp(order.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            
          )}
        </Modal.Body>
        <div className="d-flex justify-content-center">
  <Pagination>
    <Pagination.Prev 
      onClick={() => handleHistoryPageChange(orderHistoryPage - 1)} 
      disabled={orderHistoryPage === 1} 
    />
    <Pagination.Item>{orderHistoryPage}</Pagination.Item>
    <Pagination.Next 
      onClick={() => handleHistoryPageChange(orderHistoryPage + 1)} 
    />
  </Pagination>
</div>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setHistoryModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <OrderDetailsModal showModal={showModal} selectedOrder={selectedOrder} setShowModal={setShowModal} fees={fees} />

    </div>
  );
}

export default AllCustomers;
