import { getToken } from "./utlits";


const GET_ORDERS = "orders/GET_ORDERS";
const UPDATE_TIME = "orders/UPDATE_TIME";
const UPDATE_STATUS = "order/UPDATE_STATUS";
const GET_ALL_ORDERS = "orders/GET_ALL_ORDERS";
const UPDATE_TIP = "orders/UPDATE_TIP";
const UPDATE_ORDER  = "orders/UPDATE_ORDER";

const apiUrl = 'http://localhost:8000';

const getOrders = (orders) => ({
    type: GET_ORDERS,
    orders
})

const updateTime = (order) => ({
    type: UPDATE_TIME,
    order
})

const updateStatus = (order) => ({ 
    type: UPDATE_STATUS,
    order
})

const getAllOrders = (orders) => ({
    type: GET_ALL_ORDERS,
    orders
})

export const updateTip = (order) => ({
    type: UPDATE_TIP,
    order
})

export const updateOrder = (order) => ({
    type: UPDATE_ORDER,
    order

})





export const getOrdersThunk = (restaurant_id) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/orders/${restaurant_id}`, { 
        headers:{ 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        },

    })

    if (response.ok) {
        const data = await response.json();
        dispatch(getOrders(data))
    }
}

export const getAllOrdersThunk = (user_id, page) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/orders/all/${user_id}?page=${page}`, {
        headers:{ 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        },
    });
  
    if (response.ok) {
      const data = await response.json();
      dispatch(getAllOrders(data));
    }
  };
  

export const updatePrepTimeThunk = (order_id, time) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/orders/prep_time/${order_id}`, {
        method: 'PUT',
        headers:{ 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        },

        body: JSON.stringify({time})
    })

    if (response.ok) {
        const data = await response.json();
        dispatch(updateTime(data))
    }
}

export const updateStatusThunk = (order_id) => async (dispatch) => { 
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/orders/status/${order_id}`, { 
        method: 'PUT',
        headers:{ 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        },

    })

    if(response.ok){ 
        const data = await response.json(); 
        dispatch(updateStatus(data))
    }
}

export const updateTipThunk = (order_id, tip, restaurant_id) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    console.log(order_id, tip, restaurant_id)

    const response = await fetch(`/api/orders/tip/${order_id}/${restaurant_id}`, {
        method: 'PUT',
        headers:{ 
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        },

        body: JSON.stringify({tip})
    })

    if (response.ok) {
        const data = await response.json();
        dispatch(updateTip(data))
    }
}

export const deleteOrderThunk = (order_id) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/orders/${order_id}`, {
        method: 'PUT',
        headers:{ 
            'Content-Type': 'application/json',
            credentials: 'include',
        }
    })

    if (response.ok) {
        const data = await response.json();
        dispatch(updateOrder(data)) 
        
    }
}


const initialState = {orders: [], allOrders: []};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS:
            return { 
                ...state,
                orders: action.orders.orders
            }
        case GET_ALL_ORDERS:
            return {
                ...state,
                allOrders: action.orders.orders
            }
        case UPDATE_TIME:
            return {
                ...state,
                orders: state.orders.map(order => {
                    if (order.id === action.order.id) {
                        return action.order
                    } else {
                        return order
                    }
                })
            }
        case UPDATE_STATUS: 
            return {
                ...state,
                orders: state.orders.map(order => {
                    if (order.id === action.order.id) {
                        return action.order
                    } else {
                        return order
                    }
                })
            } 
        case UPDATE_TIP:
            return {
                ...state,
                orders: state.orders.map(order => {
                    if (order.id === action.order.id) {
                        return action.order
                    } else {
                        return order
                    }
                })
            }

        case UPDATE_ORDER:
            return {
                ...state,
                orders: state.orders.map(order => {
                    if (order.order_id === action.order.order_id) {
                        return action.order
                    } else {
                        return order
                    }
                }),
                allOrders: state.allOrders.map(order => {
                    if (order.order_id === action.order.order_id) {
                        return action.order
                    } else {
                        return order
                    }
                })
            }

    
        default:
            return state;
    }
}

export default ordersReducer;
